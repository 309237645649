import { graphql } from 'gatsby'

import { SiteData } from '../../types'
import { Layout } from '../components/Layout'
import { Seo } from '../components/Seo'

interface NotFoundPageProps {
  data: {
    site: SiteData
  }
  location: Location
}

const NotFoundPage = ({ data, location }: NotFoundPageProps) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <h1>404: Not Found</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
